import { config } from '../config/config';
import { GlobalTranslate, showError, HttpServices } from '../Helper';

const baseURL = `${config.server_address}/CrmDfm/Courses`;

const GetCourses = async ({ pageIndex, pageSize }) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Courses/GetAllCourses?pageIndex=${
        pageIndex + 1
      }&pageSize=${pageSize}`
    );
    return result;
  } catch (error) {
    showError(
      GlobalTranslate.t('LearningAndDevelopmentView:NotificationErrorView')
    );
    return undefined;
  }
};

export const GetAllLMSCourses = async ({ pageIndex, pageSize, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Courses/GetAllLMSCourses`,
    { params: { pageIndex, pageSize, search } }
  );
  return result;
};

const GetCoursesDetails = async (courseId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Courses/GetCourseDetailsById?courseId=${courseId}`
    );
    return result;
  } catch (error) {
    showError(
      GlobalTranslate.t('LearningAndDevelopmentView:NotificationErrorView')
    );
    return undefined;
  }
};

const GetInstructorDetails = async (courseId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Courses/GetInstructorDetailsByCourseId?courseId=${courseId}`
    );
    return result;
  } catch (error) {
    showError(
      GlobalTranslate.t('LearningAndDevelopmentView:NotificationErrorView')
    );
    return undefined;
  }
};

export const GetAllTenantLMSCourses = async () => {
  const result = await HttpServices.get(`${baseURL}/GetAllTenantLMSCourses`);
  return result;
};
export const GetAllTenantLMSUsersDetails = async (body = {}) => {
  const result = await HttpServices.post(
    `${baseURL}/GetAllTenantLMSUsersDetails`,
    body
  );
  return result;
};
export const GetUserLMSDetails = async (userEmail) => {
  const result = await HttpServices.get(
    `${baseURL}/GetUserLMSDetails/${userEmail}`,
    {
      params: { userEmail },
    }
  );
  return result;
};
export const GetUserLMSProfileOverview = async (userEmail) => {
  const result = await HttpServices.get(
    `${baseURL}/GetUserLMSProfileOverview/${userEmail}`,
    {
      params: { userEmail },
    }
  );
  return result;
};

export { GetCourses, GetCoursesDetails, GetInstructorDetails };
