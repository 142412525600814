import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    modeWrapper: {
      marginTop: "24px",
      marginBottom: "24px",
      display: "flex",
      justifyContent: "flex-end",
    },
    gridContainer: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "18px",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  };
});
