import React, { useEffect, useState, useCallback } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { TabsComponent, RadiosGroupComponent, CompletedDataComponent, Spinner } from '../../../../Components';
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout' ; 
import { GetParams, showError, showSuccess } from '../../../../Helper';
import { ContactsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { MaintenanceCompanyEnum } from '../../../../Enums';
import { ContactsMapper } from '../ContactsUtilities';
import { GetAdvanceSearchContacts , GetSensitiveFieldsForUser, UpdateContactDNCR  } from '../../../../Services';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { ContactsInformationComponent  } from './Sections/ContactsInformationComponent/ContactsInformationComponent';
import { useTranslation } from 'react-i18next';

const parentTranslationPath = 'ContactProfileManagementView';
const translationPath = '';
export const ContactProfileManagementView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const contactId = +GetParams('id');
  const contactType = +GetParams('formType');
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDNCRLoading, setIsDNCRLoading] = useState(false);
  const [contactEmptyFields , setContactEmptyFields] = useState(null) ; 

  const [activeContactData, setActiveContactData] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const [contactWithPolicy ,setContactWithPolicy ] = useState(null) ; 
  const [sensitiveFieldsForUser , setSensitiveFieldsForUser  ] = useState([]) ; 
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  // filterBy
  const [filterBy, setFilterBy] = useState({
    id: null,
    formType: null,
  });
  const [isSensitiveLoading , setIsSensitiveLoading ] = useState(0)  ;
  const [isLoadingSensitiveField, setIsLoadingSensitiveField] = useState(false);
 

  const [displayDetailsToUserDependsOnPolicy , setDisplayDetailsToUserDependsOnPolicy] = useState(null);
  const pathName = window.location.pathname
  .split('/home/')[1]
  .split('/view')[0]
  .split('/')[0]; 

  const [isRoutFromUnit , setIsRoutFromUnit] =  useState(pathName.includes('units'));

  const GetSensitiveFieldsForUserAPI  = useCallback(async (sensitiveContactId) => {
    setIsLoadingSensitiveField(true) ; 
    const allSensitiveFields  = await GetSensitiveFieldsForUser(contactWithPolicy && contactWithPolicy.contact && contactWithPolicy.contact.contact_type_id ,  contactWithPolicy && { key : 'contact' , id : sensitiveContactId} );
     
    if (!(allSensitiveFields && allSensitiveFields.status && allSensitiveFields.status !== 200)) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);
      
    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);

    }
    setIsLoadingSensitiveField(false); 
    
  }, [contactWithPolicy]);

  const updateContactDNCR = async () => {
    setIsDNCRLoading(true);
    const res = await UpdateContactDNCR(contactId);
    if (!(res && res.status && res.status !== 200)) showSuccess(`Contact DNCR Updated Successfully`);
     else showError(`Contact DNCR Update Failed..`);
    setIsDNCRLoading(false);
  };

  const userHasPolicyToDisplayData = useCallback(async (contactId) => 
  {
    setIsLoading(true);
    const isForLog = true;

    let body = {
      criteria: {
        Ids : 
        [{searchType: 1, value: contactId  }]
      } , 
      filterBy : 'createdOn' , 
      orderBy : 2 ,
    };
    const res = await GetAdvanceSearchContacts({pageIndex : 0, pageSize :2, isForLog}, body);
    if (!(res && res.status && res.status !== 200)) 
    {
      if(res && res.totalCount > 0)
      {
        if(res && res.result && res.result.length  && res.result[0].contactJson) {
          const contactData = res.result[0];
          setContactEmptyFields(JSON.parse(res && res.result &&  res.result[0] && res.result[0].contactJson)) ; 
          const contactJson =JSON.parse(contactData.contactJson);
          const activeContact = {
            contact:{
              id : contactData.contactId,
              ...contactJson.contact,
            }
          }
          setContactWithPolicy(contactJson) ; 
          setActiveContactData(activeContact);
          setDisplayDetailsToUserDependsOnPolicy('allowed');

        }     
      }
      
      else {
         setDisplayDetailsToUserDependsOnPolicy('notAllowed');
         setContactWithPolicy(null) ;  
         setActiveContactData(null);
      } 

    }
    else {
      setContactWithPolicy(null) ; 
      setActiveContactData(null);
      setDisplayDetailsToUserDependsOnPolicy('notAllowed');
    }
    setIsLoading(false);
  
  });


  useEffect(() => {

    if (contactId && activeTab === 0 )
        userHasPolicyToDisplayData(contactId);
    
    
  }, [activeTab]);

  useEffect(() => {
    const contactId = +GetParams('id');
    if (contactId && displayDetailsToUserDependsOnPolicy === 'allowed' &&  activeTab === 0  )
    {
        GetSensitiveFieldsForUserAPI(activeContactData && activeContactData && activeContactData.contact && activeContactData.contact.id   ) ; 
    }
  }, [displayDetailsToUserDependsOnPolicy , activeTab]);

  useEffect(()=>{
    if(activeContactData){
      dispatch(ActiveItemActions.activeItemRequest(ContactsMapper(activeContactData,activeContactData.contact)))
      
      if (localStorage.getItem('parentContact')) localStorage.removeItem('parentContact');
      localStorage.setItem('parentContact', JSON.stringify(activeContactData.contact));
    }
  },[activeContactData]);

const [formType, setformType] = useState(+GetParams('formType'));
  const [list, setList] = useState([]);
   
  const showMaintenance =
    activeItem &&
    activeItem.contactClassifications &&
    activeItem.contactClassifications.findIndex(
      (item) => item.lookupItemId === MaintenanceCompanyEnum.id
    ) !== -1;

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };

  const reloadData = () => 
  {
    userHasPolicyToDisplayData(+GetParams('id')) ; 
    GetSensitiveFieldsForUserAPI(activeContactData && activeContactData.contact && activeContactData.contact.id ) ; 

  };


  useEffect(() => {
    setFilterBy({
      formType: (GetParams('formType') && +GetParams('formType')) || null,
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, []);

  useEffect(() => {
    if (isRoutFromUnit) {
      setList([
        {
          label: "contact-information",
          component: ContactsInformationComponent,
        },
      ]);
    } else {
      const exludedTabs = ["maintenance-services"];
      if (+formType === 2) {
        exludedTabs.push("dncr-and-consent-management");
      }
      setList(
        showMaintenance
          ? ContactsVerticalTabsData
          : ContactsVerticalTabsData.filter(
              (item) => !exludedTabs.includes(item.label)
            )
      );
    }
  }, [ContactsVerticalTabsData]);

  useEffect(() => {
    if (location.state?.isActivityTab) {
      setActiveTab(4);
    }
  }, []);

  useEffect(() => {
    if (GetParams('tab')) {
      setActiveTab(list.findIndex((tab) => tab.label === GetParams('tab')));
    }
  }, [list]);

  return (
    <div className='contact-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      { displayDetailsToUserDependsOnPolicy === 'allowed' && 
       activeTab === 0  &&  list &&  list.length > 0  &&  list[0].label  === "contact-information" && (
        <div className='d-flex'>
        <RadiosGroupComponent
          idRef='viewDataRef'
          data={[
            {
              key: 1,
              value: 'all-data',
            },
            {
              key: 2,
              value: 'missing-data',
            },
          ]}
          value={viewType}
          labelValue='view'
          labelInput='value'
          valueInput='key'
          themeClass='theme-line'
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          onSelectedRadioChanged={onViewTypeChangedHandler}
        />
          {contactType == 1? <ButtonBase 
            className='btns theme-solid bg-primary px-4 mb-1 as-center'
            onClick={updateContactDNCR}
            disabled={isDNCRLoading}
            >
            <span className='mt-1'>{t("update-dncr-status")}</span>
          </ButtonBase> : null}
        <CompletedDataComponent  typeId='contact' formType={formType} activeItem={activeItem} completedData={activeItem && activeItem.progress} />
      </div>
      )}
      {
        displayDetailsToUserDependsOnPolicy === 'allowed' && (
        <TabsComponent
          data={list}
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-solid'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
          dynamicComponentProps={{
            id: filterBy.id,
            viewType,
            parentTranslationPath,
            translationPath,
            setActiveTab, 
            activeTab,
            contactWithPolicy , 
            sensitiveFieldsForUser , 
            isSensitiveLoading  , 
            isLoading , 
            reloadData:reloadData , 
            taskRelatedTo: 'Contact', 
            contactEmptyFields : {...contactEmptyFields} , 
            formType : 'contact'
           }}
         />
        )}
      { 
        displayDetailsToUserDependsOnPolicy === 'notAllowed' && (<PermissionDeniedLayout/>)
      }

    </div>
  );
};
