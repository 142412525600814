import { useRef } from 'react';

export const useDebouncedAction = (action, delay = 700) => {
  const timerRef = useRef();
  const debouncedAction = (payload) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => action(payload), delay);
  };
  return debouncedAction;
};
