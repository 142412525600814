import React, { useEffect, useState } from 'react';
import { Tables } from '../../../Components';
import { GetAllTenantLMSCourses } from '../../../Services';
import { showError } from '../../../Helper';
import { UserTrainingInsightsLocalePath } from '../UserTrainingInsights/i18n/UserTrainingInsightsLocale';
import { useTranslation } from 'react-i18next';
import { urlExpression } from '../../../Utils';
import { Link } from '@material-ui/core';
import { LearningHubLocalePath } from './i18n/LearningHub';
import ShowMore from '../../../SharedComponents/ShowMore/ShowMore';

function LearningHub() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(LearningHubLocalePath);
  const getAllTenantLMSCourses = async () => {
    try {
      const result = await GetAllTenantLMSCourses();
      setData(result);
    } catch (error) {
      showError(t('error_message', { ns: UserTrainingInsightsLocalePath }));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllTenantLMSCourses();
  }, []);
  return (
    <div>
      <Tables
        isLoading={loading}
        headerData={[
          { label: 'course-name', input: 'courseName' },
          { label: 'category', input: 'category' },
          {
            label: 'course-link',
            input: 'courseLink',
            component: (row) =>
              urlExpression.test(row.courseLink) ? (
                <Link href={row.courseLink} target='_blank'>
                  {t('open_course')}
                </Link>
              ) : (
                ''
              ),
          },
          {
            label: 'description',
            input: 'description',
            component: (row) => (
              <ShowMore
                text={row.description}
                title={'description'}
                parentTranslationPath={LearningHubLocalePath}
              />
            ),
          },
        ]}
        data={data}
        totalItems={data.length}
        parentTranslationPath={LearningHubLocalePath}
        itemsPerPage={data.length}
        activePage={0}
        defaultActions={[]}
      />
    </div>
  );
}

export default LearningHub;
