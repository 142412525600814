import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Divider } from "@material-ui/core";
import { useTranslate } from "../../../../../../../Hooks";
import {
  ContactOverviewCommentsCardSkeleton,
  CustomButton,
  CustomCard,
} from "../../../../../../../Components";
import { ContactComment } from "../../../../../../../Components/V2/ContactProfile/UI";
import {
  CreateMainEntitiesComment,
  GetMainEntitiesComments,
} from "../../../../../../../Services";
import { useVerticalNav } from "../../../../../../../Contexts/VerticalNavContext";

// Styles
import { commentsCardStyles } from "./styles";

function ContactOverviewCommentsCard({ contactId }) {
  const styles = commentsCardStyles();

  const { translate } = useTranslate("NewContactsView");

  const { loginResponse } = useSelector((state) => state.login);

  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);

  const { setAlertBoxContent } = useVerticalNav();

  const handleSubmitComment = async () => {
    const body = {
      dfmType: 1,
      entityId: contactId,
      comment,
    };
    try {
      if (!comment?.trim()) {
        setComment("");
        return;
      }

      await CreateMainEntitiesComment(body);

      setComments([
        {
          createdOn: new Date().toLocaleDateString(),
          creatorFullName: loginResponse.fullName,
          creatorProfileImg: loginResponse.profileImg ?? null,
          comment,
        },
        ...comments,
      ]);

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Comment added successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } catch (error) {
      console.error(error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Somthing went wrong!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setComment("");
    }
  };

  const getContactComments = useCallback(async () => {
    setIsCommentsLoading(true);

    try {
      const response = await GetMainEntitiesComments(1, contactId);
      setComments(response ?? []);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
      setComments([]);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to fetch comments!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsCommentsLoading(false);
    }
  }, []);

  useEffect(() => {
    getContactComments();
  }, []);

  // Sort comments by createdOn date in descending order
  const sortedComments = comments
    .slice()
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  if (isCommentsLoading) {
    return <ContactOverviewCommentsCardSkeleton numberOfCards={2} />;
  }

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.cardTitleWrapper}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{translate("comments")}</span>
          <span className={styles.subTitle}>
            {translate("join_conversation")}
          </span>
        </div>
      </Box>
      <Box className={styles.commentsContainer}>
        <textarea
          className={styles.textArea}
          placeholder={translate("comments_placeholder")}
          onChange={(event) => setComment(event.target.value)}
          value={comment}
        />

        <Box className={styles.actionsWrapper}>
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="contained"
            color="primary"
            disabled={!comment}
            onClick={handleSubmitComment}
          >
            {translate("post_comment")}
          </CustomButton>
        </Box>

        <Divider className={styles.divider} light />

        <Box className={styles.commentsWrapper}>
          {sortedComments.map((comment, idx) => {
            return <ContactComment key={idx} comment={comment} />;
          })}
        </Box>
      </Box>
    </CustomCard>
  );
}

export default ContactOverviewCommentsCard;
