import React, { useState } from "react";
import clsx from "clsx";
import {
  CustomBadge,
  CustomButton,
  CustomCard,
  CustomCopyToClipboard,
  CustomTimeline,
} from "../../../../../../Components";
import { Box, Typography, Divider } from "@material-ui/core";
import {
  useIsAr,
  useSelectedTheme,
  useTranslate,
} from "../../../../../../Hooks";
import {
  ArrowNarrowRight,
  ArrowNarrowLeft,
  MoreVertIcon,
  ShareIcon,
  EditIcon,
} from "../../../../../../assets/icons";
import { CustomMenu } from "../../../../../../Components";
import { useSelector } from "react-redux";

// Styles
import useStyles from "./styles";

function ContactActivityItemTimeLine({ timelineItems }) {
  return (
    <CustomTimeline
      timelineData={timelineItems}
      component={TimelineItemContent}
      timelineDotTopMargin="22px"
    />
  );
}

export default ContactActivityItemTimeLine;

function TimelineItemContent({ item }) {
  const styles = useStyles();

  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const [isExpandedLeadSourceText, setIsExpandedLeadSourceText] =
    useState(false);

  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);
  const MAX_WORDS_SIZE = 16;
  const getContent = (content = "", showFullText) => {
    const firstNewLineIndex = content.indexOf("\n");

    if (firstNewLineIndex !== -1 && !showFullText) {
      return `${content.slice(0, firstNewLineIndex)}...`;
    }
    const allContent = content && content.split(" ");
    return allContent && allContent.length > MAX_WORDS_SIZE && !showFullText
      ? `${allContent.slice(0, MAX_WORDS_SIZE).join(" ")}...`
      : content;
  };

  return (
    <Box className={styles.timelineContentWrapper}>
      <CustomCard
        boxShadow="xs"
        borderRadius="xl"
        borderColor="secondary"
        classes={styles.container_Contact_Activity_Item_Crad}
      >
        <Box className={styles.container_Info_Activity}>
          <Box className={styles.container_LeadInfo}>
            <strong className={styles.title_lead_info}>
              {translate("new-lead-added-CRM")}
            </strong>
            <Box className={styles.container_lead_name}>
              <Typography className={styles.title_lead} component="span">
                {translate("lead_name")}
              </Typography>
              <strong className={styles.infoValue}>
                {item?.relatedLeadFullName || "N/A"}
              </strong>
            </Box>
            <Box className={styles.container_leadId}>
              <Typography className={styles.infoItem} component="span">
                {translate("lead_id")}
              </Typography>
              <CustomCopyToClipboard
                data={
                  item?.relatedLeadNumberId
                    ? String(item.relatedLeadNumberId)
                    : ""
                }
                childrenData={
                  item?.relatedLeadNumberId
                    ? "#" + String(item?.relatedLeadNumberId)
                    : "N/A"
                }
                fontSize="14px"
                copyDoneShowTime={1000}
              />
            </Box>

            <Box className={styles.container_status}>
              <Typography
                className={clsx(styles.open_close_status, {
                  [styles.open_status]: !item?.isOpen,
                  [styles.close_status]: item?.isOpen,
                })}
                component="span"
              >
                {item?.isOpen ? translate("close") : translate("open")}
              </Typography>
              <CustomBadge
                className={clsx(styles.positive_negative_Status, {
                  [styles.open_status]: !item?.positive_Status,
                  [styles.close_status]: item?.negative_Status,
                })}
                label={
                  item?.isOpen ? translate("negative") : translate("positive")
                }
                SizeVariant={"small"}
                BackgroundColor={
                  palette.utility[item?.isOpen ? "error_50" : "success_50"]
                }
                BorderColor={
                  palette.utility[item?.isOpen ? "error_200" : "success_200"]
                }
                Color={
                  palette.utility[item?.isOpen ? "error_700" : "success_700"]
                }
              ></CustomBadge>
            </Box>
          </Box>
          <Box className={styles.container_LeadScore}>
            <Box className={styles.lead_Score_Info}>
              <Typography className={styles.infoItem} component="span">
                {translate("score")}:
                <strong className={styles.infoValue}>
                  {item?.leadScore ? item?.leadScore : "N/A"}
                </strong>
              </Typography>
              <Box className={styles.container_Created_Assign}>
                <Typography className={styles.infoItem} component="span">
                  {translate("created_by")}
                  <strong className={styles.Assign_to_createdBy_value}>
                    {item?.createdByName ? item?.createdByName : "N/A"}
                  </strong>
                </Typography>
                <Box className={styles.container_arrow_assign_to}>
                  {isAr ? (
                    <ArrowNarrowLeft
                      width="24"
                      height="24"
                      fill={
                        palette.foreground.navigation["nav-item-button-icon-fg"]
                      }
                    />
                  ) : (
                    <ArrowNarrowRight
                      width="24"
                      height="24"
                      fill={
                        palette.foreground.navigation["nav-item-button-icon-fg"]
                      }
                    />
                  )}
                  <Typography className={styles.infoItem} component="span">
                    {translate("assign-to")}:
                    <strong className={styles.Assign_to_createdBy_value}>
                      {item?.assignedTo ? item?.assignedTo : "N/A"}
                    </strong>
                  </Typography>
                </Box>
              </Box>
              {item?.comments && (
                <Box>
                  <Divider className={styles.divider}></Divider>
                  <Box className={styles.container_lead_source}>
                    <Typography className={styles.lead_Source_Text}>
                      {getContent(item?.comments, isExpandedLeadSourceText)}
                    </Typography>
                    {item?.comments?.split(" ").length > MAX_WORDS_SIZE ||
                      (item?.comments?.includes("\n") && (
                        <CustomButton
                          boxShadow="none"
                          size="sm"
                          variant="text"
                          color="tertiary"
                          style={{ display: "flex", alignItems: "flex-start" }}
                          onClick={() => {
                            setIsExpandedLeadSourceText(
                              !isExpandedLeadSourceText
                            );
                          }}
                        >
                          {isExpandedLeadSourceText
                            ? translate("hide")
                            : translate("show")}
                        </CustomButton>
                      ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={styles.continerDotIcon}>
          <CustomMenu
            menuItems={[
              item?.isReplyAble && {
                icon: (
                  <ShareIcon
                    width="20"
                    height="20"
                    fill={
                      palette.foreground.navigation["nav-item-button-icon-fg"]
                    }
                  />
                ),
                handler: () => {},
                text: translate("replay"),
              },
              {
                icon: (
                  <EditIcon
                    width="18.23"
                    height="18.23"
                    fill={
                      palette.foreground.navigation["nav-item-button-icon-fg"]
                    }
                  />
                ),
                handler: () => {},
                text: translate("edit"),
              },
            ].filter(Boolean)}
            activeIconClass={styles.menuActiveIconClass}
            defaultIconClass={styles.menuDefaultIconClass}
            customTransformOrigin={
              isAr
                ? { vertical: "top", horizontal: "left" }
                : { vertical: "top", horizontal: "right" }
            }
          >
            <MoreVertIcon
              width="20"
              height="20"
              fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
            />
          </CustomMenu>
        </Box>
      </CustomCard>
    </Box>
  );
}
