import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import {
  AssociatedContactCard,
  AssociatedContactCardSkeleton,
  BasicTable,
  DeleteConfirmationDialog,
  NoDataFound,
  TableEditDeleteActions,
  TableSkeleton,
  VIEW_MODES,
  ViewModeSwitcher,
} from "../../../../../../Components";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { DeleteAssociatedContactV2, GetAssociatedContactByContactId } from "../../../../../../Services";
import { associatedContactTableHeaderData } from "./associatedContactTableHeaderData";
import { useTranslate } from "../../../../../../Hooks";

// Styles
import useStyles from "./styles";

function AssociatedContacts({ contactId }) {
  const styles = useStyles();

  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedTableContact, setSelectedTableContact] = useState(null);

  const { isDarkMode } = useSelector((state) => state.theme);
  const { translate } = useTranslate("NewContactsView");

  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const { setAlertBoxContent } = useVerticalNav();
  
  const getAssociatedContacts = async () => {
    setIsLoading(true);

    try {
      const res = await GetAssociatedContactByContactId({
        id: contactId,
        filterBy: "RelatedContactName",
        orderBy: 1,
      });

      if (!(res?.status && res.status !== 200)) setContacts(res);
      else setContacts([]);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleDelete = async () => {
    try {
      await DeleteAssociatedContactV2(selectedContact?.associatedContactId);

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Associated Contact Deleted Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      // Filter out the deleted contact from the contacts array
      setContacts((prevContacts) =>
        prevContacts.filter(
          (contact) => contact.associatedContactId !== selectedContact.associatedContactId
        )
      );
    } catch (error) {
      console.error("Failed to Delete Associated Contact:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Delete Associated Contact!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setSelectedContact(null);
      setSelectedTableContact(null);
    }
  };
  
  useEffect(() => {
    if (contactId) {
      getAssociatedContacts();
    }
  }, [contactId]);
  
  return (
    <>
      <Box>
        <Box className={styles.modeWrapper}>
          <ViewModeSwitcher
            viewMode={viewMode}
            onChangeViewMode={(value) => setViewMode(value)}
            isLoading={isLoading}
          />
        </Box>
          {viewMode === VIEW_MODES.GRID && (
        <Box className={styles.gridContainer}>
          {isLoading ? (
            <AssociatedContactCardSkeleton
            numberOfCardsSkeleton={8}
            />
          ) : contacts?.length > 0 ? (
            contacts?.map((contact) => (
              <AssociatedContactCard
                key={contact?.associatedContactId}
                contact={contact}
              />
            ))
          ) : (
            <NoDataFound title="associated contacts" />
          )}
        </Box>
      )}
        {viewMode === VIEW_MODES.TABLE && (
          <Box>
            {isLoading ? (
              <TableSkeleton rowsNum={25} />
            ) : contacts?.length > 0 ? (
              <BasicTable
                tableActions={({ anchorPosition }) => {
                  if (Boolean(selectedTableContact))
                    return (
                      <TableEditDeleteActions
                        anchorPosition={anchorPosition}
                        onDelete={() => setSelectedContact(selectedTableContact)}
                        onEdit={() => console.log("Edit clicked")}
                      />
                    );
                }}
                rowsData={
                  contacts?.map((contact) => ({
                    ...contact,
                    id: contact.relatedAssociatedContactsId,
                  })) ?? []
                }
                setActiveItemOnRowClick={(contact) => {
                  setSelectedTableContact(contact);
                }}
                rowHeight={84}
                columns={associatedContactTableHeaderData(isDarkMode)}
              />
            ) : (
              <NoDataFound title="associated contacts" />
            )}
          </Box>
        )}
      </Box>

      {Boolean(selectedContact) &&
        <DeleteConfirmationDialog
          title={translate("associated-contacts-delete-confirmation-title")}
          subTitle={translate("associated-contacts-delete-confirmation-sub-title")}
          onClose={() => {
            setSelectedContact(null);
            setSelectedTableContact(null);
          }}
          onConfirm={() => {
            handleDelete();
          }}
        />
      }
    </>
  );
}

export default AssociatedContacts;
