import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { Spinner, Inputs } from "../../../../../Components";
import { FooterWrapperComponent } from "../../DevelopersUtilities/Components/FooterWrapperComponent";
import { GetParams, showError, showSuccess } from "../../../../../Helper";
import { UpdateSocialMediaLogoAPI } from "../../../../../Services";
import {
  matchYoutubeUrl,
  matchFacebookUrl,
  matchInstagramUrl,
  matchLinkedinUrl,
  matchSnapchatUrl,
  matchTiktokLink,
  matchXLink , 
} from "../../../../../Helper/Link.helper";

export const SocialMediaLinkComponent = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  socialMediaLinkValues,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({ update: false });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    linkedInLink: null,
    facebookLink: null,
    instagramLink: null,
    snapchatLink: null,
    tikTokLink: null,
    youtubeLink: null,
    xLink: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const checkIsValidValues = (key) => {
    if (key === "linkedInLink" && state.linkedInLink)
      return  !matchLinkedinUrl(state.linkedInLink || "");
    else if (key === "facebookLink" && state.facebookLink)
      return !matchFacebookUrl(state.facebookLink || "");
    else if (key === "instagramLink" && state.instagramLink)
      return !matchInstagramUrl(state.instagramLink || "");
    else if (key === "snapchatLink" && state.snapchatLink)
      return !matchSnapchatUrl(state.snapchatLink || "");
    else if (key === "youtubeLink"  && state.youtubeLink)
      return !matchYoutubeUrl(state.youtubeLink || "");
    else if (key === "tikTokLink"  && state.tikTokLink)
      return !matchTiktokLink(state.tikTokLink || "");
    else if (key === "xLink"  && state.xLink)
      return !matchXLink(state.xLink || "");
   
    return false;
  };

  const checkIsSaveValidValues = () => {
     let checkValues  = null ;
      checkValues = {
        linkedInLink :{ id : 'linkedInLink' ,  value :  state.linkedInLink !=='' && state.linkedInLink !== null  ?  matchLinkedinUrl(state.linkedInLink || "" ) : true  }, 
        facebookLink :{ id : 'facebookLink' ,  value :  state.facebookLink !=='' && state.facebookLink !== null ? matchFacebookUrl(state.facebookLink || "") :  true  }, 
        instagramLink :{ id : 'instagramLink' ,  value :  state.instagramLink !=='' && state.instagramLink !== null ? matchInstagramUrl(state.instagramLink || "")  : true }, 
        snapchatLink :{ id : 'snapchatLink' ,  value : state.snapchatLink !=='' && state.snapchatLink !== null ? matchSnapchatUrl(state.snapchatLink || "") : true  }, 
        youtubeLink :{ id : 'youtubeLink' ,  value :  state.youtubeLink !=='' && state.youtubeLink !== null ? matchYoutubeUrl(state.youtubeLink || "") : true  }, 
        tikTokLink :{ id : 'tikTokLink' ,  value :  state.tikTokLink !==''  && state.tikTokLink !== null? matchTiktokLink(state.tikTokLink || "") : true  }, 
        xLink :{ id : 'xLink' ,  value : state.xLink !=='' && state.xLink !== null ? matchXLink(state.xLink || "") : true }, 
      }
    const  saveValues =  [...Object.values(checkValues)] ;  
    return  saveValues.some((x) => x.value === false) ; 
  };
  const UpdateSocialMediaLinkHandler = async () => {
    setIsSubmitted(true);
    setIsLoading((item) => ({ ...item, edit: true }));
    const res = await UpdateSocialMediaLogoAPI(socialMediaLinkValues.socialMediaLogoId , state);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}social-media-logo-updated-successfully`);
      setIsLoading((item) => ({ ...item, edit: false }));
      onSave(true);
    } else {
      showError(t`${translationPath}social-media-logo-updated-failed`);
      setIsLoading((item) => ({ ...item, edit: false }));
    }
    setIsSubmitted(false);
  };

  useEffect(() => {
    if (socialMediaLinkValues) {
      const updateState = {
        developerContactId:
          socialMediaLinkValues?.developerContactId || +GetParams("id"),
        socialMediaLogoId: socialMediaLinkValues?.socialMediaLogoId,
        linkedInLink: socialMediaLinkValues?.linkedInLink,
        facebookLink: socialMediaLinkValues?.facebookLink,
        instagramLink: socialMediaLinkValues?.instagramLink,
        snapchatLink: socialMediaLinkValues?.snapchatLink,
        tikTokLink: socialMediaLinkValues?.tikTokLink,
        youtubeLink: socialMediaLinkValues?.youtubeLink,
        xLink: socialMediaLinkValues.xLink,
      };
      setState({ id: "edit", value: updateState });
    }
  }, [socialMediaLinkValues]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <Spinner isActive={isLoading.info} isAbsolute />
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-social-media-link`)}</span>
        </DialogTitle>
        <DialogContent className="dialog-content-wrapper">
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"LinkedInLinkRef"}
              labelValue={"linkedIn-link"}
              type={"text"}
              value={state.linkedInLink || ""}
              onInputChanged={(e) => {
                setState({ id: "linkedInLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.linkedInLink
                        ? state.linkedInLink !== ""
                          ? checkIsValidValues("linkedInLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.linkedInLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-linkedIn-url`}
              error={
                state?.linkedInLink !== ""
                  ? checkIsValidValues("linkedInLink")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>

          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"Facebook LinkRef"}
              labelValue={"facebook-link"}
              type={"text"}
              value={state.facebookLink || ""}
              onInputChanged={(e) => {
                setState({ id: "facebookLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.facebookLink
                        ? state.facebookLink !== ""
                          ? checkIsValidValues("facebookLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.facebookLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-facebook-link-url`}
              error={
                state?.facebookLink !== ""
                  ? checkIsValidValues("facebookLink")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"instagramLinkRef"}
              labelValue={"instagram-link"}
              type={"text"}
              value={state.instagramLink || ""}
              onInputChanged={(e) => {
                setState({ id: "instagramLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.instagramLink
                        ? state.instagramLink !== ""
                          ? checkIsValidValues("instagramLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.instagramLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-instagram-link-url`}
              error={
                state?.instagramLink !== ""
                  ? checkIsValidValues("instagramLink")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"InstagramAccountNumberRef"}
              labelValue={"instagram-account-number"}
              type={"text"}
              value={state.instagramAccountNumber || ""}
              onInputChanged={(e) => {
                setState({
                  id: "instagramAccountNumber",
                  value: e.target.value,
                });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"SnapchatLinkRef"}
              labelValue={"snapchat-link"}
              type={"text"}
              value={state.snapchatLink || ""}
              onInputChanged={(e) => {
                setState({ id: "snapchatLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.snapchatLink
                        ? state.snapchatLink !== ""
                          ? checkIsValidValues("snapchatLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.snapchatLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-snapchat-link-url`}
              error={
                state?.snapchatLink !== ""
                  ? checkIsValidValues("snapchatLink")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              value={state.tikTokLink || ""}
              idRef={"TikTokLinkRef"}
              labelValue={"tiktok-link"}
              type={"text"}
              onInputChanged={(e) => {
                setState({ id: "tikTokLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.tikTokLink
                        ? state.tikTokLink !== ""
                          ? checkIsValidValues("tikTokLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.tikTokLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-tikTok-link-url`}
              error={
                state?.tikTokLink !== ""
                  ? checkIsValidValues("tikTokLink")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              value={state.youtubeLink || ""}
              idRef={"YouTubeLinkRef"}
              labelValue={"youTube-link"}
              type={"text"}
              onInputChanged={(e) => {
                setState({ id: "youtubeLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.tikTokLink
                        ? state.youtubeLink !== ""
                          ? checkIsValidValues("youtubeLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.youtubeLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-youtube-link-url`}
              error={
                state?.youtubeLink !== ""
                  ? checkIsValidValues("youtubeLink")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              value={state.xLink || ""}
              idRef={"XRef"}
              labelValue={"x-link"}
              type={"text"}
              onInputChanged={(e) => {
                setState({ id: "xLink", value: e.target.value });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.xLink
                        ? state.xLink !== ""
                          ? checkIsValidValues("xLink")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.xLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-x-link-url`}
              error={state?.xLink !== "" ? checkIsValidValues("xLink") : false}
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={UpdateSocialMediaLinkHandler}
            disabled={state.linkedInLink === "" || !state.linkedInLink || checkIsSaveValidValues()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
