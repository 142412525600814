import React, { useState, useEffect, useRef, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
  Spinner,
} from "../../../../../Components";
import { GetParams, showError, showSuccess } from "../../../../../Helper";
import {
  GetAuditAndComplianceByDeveloperContactIdAPI,
  CreateAuditAndComplianceAPI,
} from "../../../../../Services";
import { ActivityFilesUploader } from "../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";
import { AuditAndComplianceTable } from "./AuditAndComplianceComponents";

export const AuditAndCompliance = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isLoading, setIsLoading] = useState({
    allAuditAndCompliance: false,
    addAuditAndCompliance: false,
  });

  const [items, setItems] = useState({
    allAuditAndCompliance: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    isAuditProcessComplete: false,
    auditRemarks: null,
    auditDate: null,
    auditAndComplianceDocuments: [],
    files: null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    auditProcessComplete: null,
    auditAndComplianceDocuments: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const GetAuditAndComplianceByDeveloperContactId = async (search) => {
    setIsLoading((item) => ({ ...item, allAuditAndCompliance: true }));
    const rs = await GetAuditAndComplianceByDeveloperContactIdAPI(
      +GetParams("id"),
      {
        pageIndex: filter.pageIndex + 1,
        pageSize: filter.pageSize,
        name: filter.search,
      }
    );
    if (!rs) {
      setItems((item) => ({
        ...item,
        allAuditAndCompliance: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allAuditAndCompliance: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allAuditAndCompliance: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetAuditAndComplianceByDeveloperContactId();
  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const AddAuditAndComplianceHandler = async () => {
    setIsLoading((item) => ({ ...item, addAuditAndCompliance: true }));
    const body = { ...state };
    if (state.files) delete body.files;
    const res = await CreateAuditAndComplianceAPI(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t`${translationPath}audit-and-compliance-created-successfully`
      );
      setIsLoading((item) => ({ ...item, addAuditAndCompliance: false }));
      reloadData();
    } else {
      showError(t`${translationPath}audit-and-compliance-create-failed`);
      setIsLoading((item) => ({ ...item, addAuditAndCompliance: false }));
    }
  };

  useEffect(() => {
    reloadData();
  }, [filter]);

  useEffect(() => {
    if (state && state.files)
      onChangeHandler(
        "auditAndComplianceDocuments",
        state.files,
        "auditAndComplianceDocuments",
        state.files
      );
  }, [state.files]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner isActive={isLoading.allAuditAndCompliance} isAbsolute />
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item mt-1">
              <AutocompleteComponent
                key={`auditAndCompliance`}
                idRef={`auditAndComplianceRef`}
                multiple={false}
                selectedValues={selected.auditProcessComplete}
                inputPlaceholder={t("select-audit-process-complete")}
                data={[
                  { key: true, name: "yes" },
                  { key: false, name: "no" },
                ]}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) =>
                  (option && t(`${translationPath}${option.name}`)) || ""
                }
                withoutSearchButton
                labelValue={"audit-process-complete"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onChange={(e, newValue) => {
                  onChangeHandler(
                    "isAuditProcessComplete",
                    newValue === null ? false : newValue.key,
                    "auditProcessComplete",
                    newValue
                  );
                }}
              />
            </div>
            <div className="px-2 item">
              <DatePickerComponent
                idRef={"auditDateRef"}
                labelValue={"select-audit-date"}
                value={
                  state &&
                  state.auditDate &&
                  moment(state.auditDate).isValid() &&
                  moment(state.auditDate).format("YYYY-MM-DDTHH:mm:ss")
                }
                onDateChanged={(newValue) => {
                  onChangeHandler(
                    "auditDate",
                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss") || newValue
                  );
                }}
                placeholder={"select-audit-date"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={`AuditRemarks${1}`}
                labelValue={"audit-remarks"}
                multiline
                rows={3}
                value={state.auditRemarks || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("auditRemarks", value);
                }}
                inputPlaceholder={"enter-audit-remarks"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>
          <div className="w-100">
            <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={true}
              accept={"/*"}
            />
          </div>
          <div className="d-flex-v-center-h-end flex-wrap">
            <ButtonBase
              className="btns theme-solid"
              onClick={AddAuditAndComplianceHandler}
              disabled={isLoading.addAuditAndCompliance || !selected.auditProcessComplete}
            >
              <span className="mdi mdi-plus" />
              {t(`${translationPath}add-audit-and-compliance`)}
            </ButtonBase>
          </div>
        </div>
      </div>
      <AuditAndComplianceTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allAuditAndCompliance}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
      />
    </div>
  );
};
