import React, { useState, useCallback, useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { dncrTableHeaderData } from "./dncrTableHeaderData";
import {
  BasicTable,
  TableSkeleton,
  CustomPagination,
  CustomTableHeader,
  NoDataFound,
  DNCRSwitcher,
  CustomButton,
  DNCR_TAB_MODES,
} from "../../../../../../Components";
import {
  GetLookupItemsByLookupTypeName,
  GetConsentLogsByContactId,
} from "../../../../../../Services";
import { useSelectedTheme } from "../../../../../../Hooks";

import useStyles from "./styles";

// Icons
import { ReassignOwnerIcon, PhoneXIcon } from "../../../../../../assets/icons";

function ContactDNCR({ contactId }) {
  const styles = useStyles();
  const { isDarkMode } = useSelector((state) => state.theme);
  const [viewMode, setViewMode] = useState(DNCR_TAB_MODES.DNCR);
  const [isLoading, setIsLoading] = useState(true);
  const [criteriaFilter, setCriteriaFilter] = useState({
    contactId: [{ searchType: 1, value: contactId }],
  });
  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageIndex: 1,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);
  const [data, setData] = useState({
    DNCRReasons: [],
    result: [],
    totalCount: 0,
  });

  const {
    theme: { palette },
  } = useSelectedTheme();

  const onChangeViewMode = (value) => {
    setViewMode(value);
  };

  const fetchConsentLogs = async () => {
    try {
      const res = await GetConsentLogsByContactId(contactId, {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });

      if (!(res && res.status && res.status !== 200)) {
        setData((prevData) => ({
          ...prevData,
          result: res.result,
          totalCount: res.totalCount,
        }));
      } else {
        setData((prevData) => ({ ...prevData, result: [] }));
      }
    } catch (error) {
      console.error("Error fetching consent logs:", error);
      setData((prevData) => ({ ...prevData, result: [] }));
    }
  };

  const fetchDNCRReasons = async () => {
    try {
      const res = await GetLookupItemsByLookupTypeName({
        lookUpName: "DNCRReasons",
        pageIndex: 0,
        pageSize: 99999,
      });
      if (!(res && res.status && res.status !== 200)) {
        setData({
          DNCRReasons: res.result,
          result: [],
          tableRowData: [],
          totalCount: 0,
        });
      } else {
        setData({
          DNCRReasons: [],
          result: [],
          tableRowData: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Error fetching DNCR reasons:", error);
      setData({ DNCRReasons: [], result: [], tableRowData: [], totalCount: 0 });
    }
  };

  const loadDNCRAndConsentData = async () => {
    setIsLoading(true);
    await fetchDNCRReasons();
    await fetchConsentLogs();
    setIsLoading(false);
  };

  useEffect(() => {
    if (contactId) {
      loadDNCRAndConsentData();
    }
  }, [contactId, pagination]);

  useEffect(() => {
    if (data?.result?.length > 0) {
      const ids = data?.DNCRReasons?.map((item) => item.lookupItemId);

      if (viewMode === DNCR_TAB_MODES.DNCR) {
        setData((prevData) => ({
          ...prevData,
          tableRowData: data?.result?.filter((item) =>
            ids?.includes(item?.changeReasonId)
          ),
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          tableRowData: data?.result?.filter(
            (item) => !ids?.includes(item?.changeReasonId)
          ),
        }));
      }
    }
  }, [viewMode, data?.result]);

  const handleFilterConfirm = (
    params,
    title,
    inputValue,
    selectedValue,
    inputNameValue,
    selectedNameValue
  ) => {};

  return (
    <Box>
      <Box className={styles.buttonsContainer}>
        <CustomButton
          boxShadow={"none"}
          size="lg"
          variant="outlined"
          color={"secondary"}
          onClick={() => {}}
          startIcon={
            viewMode === DNCR_TAB_MODES.DNCR ? (
              <PhoneXIcon
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            ) : (
              <ReassignOwnerIcon
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            )
          }
        >
          {viewMode === DNCR_TAB_MODES.DNCR
            ? "Do not call registry"
            : "Consent management"}
        </CustomButton>

        <DNCRSwitcher
          dncrMode={viewMode}
          onChangeViewMode={onChangeViewMode}
          isLoading={isLoading}
        />
      </Box>
      <Box>
        {isLoading ? (
          <TableSkeleton rowsNum={12} />
        ) : data?.tableRowData?.length > 0 ? (
          <BasicTable
            rowsData={data?.tableRowData?.map((item) => ({
              ...item,
              id: item?.consentAndDNCRLogId,
            }))}
            setActiveItem={() => {}}
            rowHeight={72}
            columns={dncrTableHeaderData(isDarkMode)
              .filter(
                (column) =>
                  viewMode === DNCR_TAB_MODES.DNCR
                    ? column.field !== "mainConsentName" // Exclude "mainConsentName" in DNCR mode
                    : column.field !== "dncrName" // Exclude "dncrName" in other modes
              )
              .map((column) => ({
                ...column,
                renderHeader: (params) => (
                  <CustomTableHeader
                    params={params}
                    title={column.headerName}
                    handleFilterConfirm={handleFilterConfirm}
                    criteriaFilter={criteriaFilter}
                  />
                ),
              }))}
          />
        ) : (
          <NoDataFound title="leads" />
        )}
      </Box>

      <CustomPagination
        hideInMobile
        totalItems={data?.totalCount}
        itemsPerPage={pagination.pageSize}
        currentPage={pagination.pageIndex}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
        isLoading={isLoading}
      />
    </Box>
  );
}

export default ContactDNCR;
